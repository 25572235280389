import { ICellRendererParams } from "@ag-grid-community/core";
import { toast } from "react-toastify";

function onCopy(ticketNumberUrl: string) {
    navigator.clipboard.writeText(ticketNumberUrl).then(() => {
        toast.success(`Link copied!`, { autoClose: 500 });
    }).catch(err => {
        toast.error(`Failed to copy!`, { autoClose: 500 });
    });
}

function TicketNumberUrlRenderer(params: ICellRendererParams) {
    let link: JSX.Element = <></>
    if (params.data && params.data.ticketNumberUrl && params.data.ticketNumber) {
        link = (
            <div className="link-container">
                <div className="link-content">
                    <a href={`${params.data.ticketNumberUrl}`} target="_blank" rel="noreferrer">
                        {`${params.data.ticketNumber}`}
                    </a>
                    <span
                        data-uk-icon="icon: copy; ratio: .8"
                        className="uk-margin-small-left copy-icon"
                        onClick={() => onCopy(params.data.ticketNumberUrl)} />
                </div>
            </div>
        );
    }
    return link;
}

export default TicketNumberUrlRenderer